import type { Channel } from "phoenix";
import type { PresenceInfo, PresenceStruct } from "~/utils/hooks";
import { atom, useSetAtom } from "jotai";
import _ from "lodash";
import { Presence, Socket } from "phoenix";
import { useEffect, useMemo, useState } from "react";
import { SOCKET_URL } from "~/constants";
import { SocketContext } from "~/contexts";
import { useCurrentUser } from "~/utils/auth";

// Don't want to include presences in the main socket context
export const appPresencesAtom = atom<Array<PresenceInfo>>([]);

type Props = {
  children: React.ReactNode;
};

export default function SocketWrapper({ children }: Props) {
  const currentUser = useCurrentUser();
  const [socket, setSocket] = useState<Socket>();
  const [appChannel, setAppChannel] = useState<Channel>();
  const setPresences = useSetAtom(appPresencesAtom);

  useEffect(() => {
    const socket = new Socket(SOCKET_URL, {
      params: {
        user_id: currentUser.id,
        version: `${import.meta.env.VITE_SENTRY_RELEASE || "N/A"} (${
          import.meta.env.VITE_SENTRY_RELEASE_DATE || "N/A"
        })`
      }
    });
    socket.connect();
    const channel = socket.channel("app");
    const presence = new Presence(channel);
    presence.onSync(() => {
      const state = (presence as unknown as { state: PresenceStruct }).state;
      const all = _.flatMap(state, (value) => value.metas);
      setPresences(all);
    });
    channel.join();
    setSocket(socket);
    setAppChannel(channel);
    return () => {
      socket.disconnect();
      setSocket(undefined);
      setAppChannel(undefined);
    };
  }, [setPresences, currentUser.id]);

  const socketData = useMemo(
    () => ({ socket, appChannel }),
    [socket, appChannel]
  );

  return (
    <SocketContext.Provider value={socketData}>
      {children}
    </SocketContext.Provider>
  );
}
